import axios from "axios";

const TOKEN_NAME = process.env.VUE_APP_AUTH_TOKEN_NAME;

const getHeaders = () => {
  if (localStorage.getItem(TOKEN_NAME)) {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem(TOKEN_NAME),
    };
  }

  return {
    'Content-Type': 'application/json',
  };
}

export const getAffiliations = async (payload) => {
  return await axios
    .get(process.env.VUE_APP_FFME_BACK_URL + `/api/affiliations`,
      {
        headers: getHeaders(),
        params: payload
      }
    )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
    });
}
