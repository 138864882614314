<template>
  <v-app-bar flat class="subbar">
    <v-row>
      <v-col cols="2" v-if="!closeModal && !showMoneyOutsWindow && external">
        <v-btn
          v-if="showSubBar"
          :href="external"
          class="gestionduclub__subbar-back-icon"
          text
          exact
        >
          <v-icon left large>mdi-chevron-left</v-icon> Retour
        </v-btn>
      </v-col>

      <v-col cols="2" v-if="!closeModal && !showMoneyOutsWindow && !external">
        <v-btn
          v-if="showSubBar"
          :to="{ name: route_name }"
          class="gestionduclub__subbar-back-icon"
          text
          exact
        >
          <v-icon left large>mdi-chevron-left</v-icon> Retour
        </v-btn>
      </v-col>

      <v-col cols="2" v-if="closeModal">
        <v-btn
          v-if="showSubBar"
          @click="$emit('closeBrouillard')"
          class="gestionduclub__subbar-back-icon"
          text
          exact
        >
          <v-icon left large>mdi-chevron-left</v-icon> Retour
        </v-btn>
      </v-col>

      <v-col cols="2" v-if="showMoneyOutsWindow">
        <v-btn
          v-if="showSubBar"
          @click="setOpenMoneyOutsWindow"
          class="gestionduclub__subbar-back-icon"
          text
          exact
        >
          <v-icon left large>mdi-chevron-left</v-icon> Retour
        </v-btn>
      </v-col>

      <v-col class="d-flex justify-space-around">
        <v-toolbar-title
          class="text-uppercase subtitle-1 font-weight-bold"
          v-if="isLicence"
          >{{ title }}</v-toolbar-title
        >
        <v-toolbar-title
          class="text-uppercase subtitle-1 font-weight-bold"
          v-else
        >
          <span style="color:#002755">
            Ma licence
          </span>
          <span style="color:#929292">
            {{ saison }}
          </span>
        </v-toolbar-title>
      </v-col>
      <v-col cols="2" class="d-flex justify-end" />
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'SubBar',

  computed: {
    ...mapState({
      openMoneyOuts: (state) => state.structure.openMoneyOuts,
    }),

    showMoneyOutsWindow() {
      return this.openMoneyOutsWindow;
    },
  },

  methods: {
    setOpenMoneyOutsWindow() {
      // this.openMoneyOutsWindow = false;
      this.$store.commit('structure/SET_OPEN_MONEY_OUTS', false);
    },
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    route_name: {
      type: String,
      default: 'Home',
    },
    showSubBar: {
      type: Boolean,
      default: true,
    },
    isLicence: {
      type: Boolean,
      default: true,
    },
    saison: {
      type: String,
    },
    closeModal: {
      type: Boolean,
    },
    openMoneyOutsWindow: {
      type: Boolean,
    },
    external: {
      type: String,
    },
  },
};
</script>
