<template>
  <div class="h-100 position-relative overflow-auto">
    <v-row no-gutters>
      <SubBar :title="subBarTitle" :route_name="route_name" />
    </v-row>
    <v-row class="m-0">
      <v-col cols="12">
        <h2 class="title-section">Détail des dépenses</h2>
      </v-col>
      <v-col class="py-0" cols="12">
        <div class="container-alert">
          <span class="m-3 p-3">
            Cette fonction permet d'éditer la liste des dépenses et le solde de
            votre club mois par mois. Ces détails des dépenses ont valeur de
            facture.
          </span>
        </div>
      </v-col>
      <v-col cols="12">
        <ValidateForm @validated="viewSpend" :showFieldObl="false">
          <v-row
            class="container-print m-0 px-3 justify-content-center position-relative"
          >
            <v-col
              class="d-flex pl-0 justify-content-center position-relative"
              sm="12"
              md="3"
              lg="4"
            >
              <v-select
                :items="months"
                v-model="month"
                item-value="id"
                item-text="mois"
                label="Choisir un mois"
              ></v-select>
            </v-col>
            <v-col
              class="d-flex pl-0 justify-content-center position-relative"
              sm="12"
              md="3"
              lg="4"
            >
              <v-select
                :items="years"
                v-model="year"
                item-value="id"
                item-text="Annee"
                label="Choisir une année"
              ></v-select>
            </v-col>
            <v-col
              class="d-flex pl-0 justify-content-center position-relative"
              sm="12"
              md="6"
              lg="4"
            >
              <v-btn type="submit" class="btn btn-primary my-2"
                >Voir le détail des dépenses</v-btn
              >
            </v-col>
          </v-row>
        </ValidateForm>
      </v-col>
      <v-col cols="12" v-if="isAdmin">
        <v-row class="m-0">
          <v-col class="d-flex" cols="12">
            <h3 class="title-sous-section">Détails du chèque</h3>
            <v-divider class="ml-5"></v-divider>
          </v-col>
          <v-col cols="12">
            <ValidateForm @validated="saveChequePaiement" :showFieldObl="false">
              <v-row>
                <v-col sm="12" md="6" lg="6">
                  <DatePicker
                    v-model="changedDate"
                    rules="min:3"
                    label="Date de réception du chèque"
                    required
                  ></DatePicker>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <InputText
                    v-model="globalAmount"
                    label="Montant global du chèque"
                    required
                  ></InputText>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <InputText v-model="bank" label="Banque" required></InputText>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <InputText
                    v-model="bankCheck"
                    label="Numéro de chèque"
                    required
                  ></InputText>
                </v-col>
                <v-col cols="12">
                  <h3 class="title-sous-section">Nature de l'opération</h3>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <InputText
                    v-model="affiliationAmount"
                    label="Affiliation"
                  ></InputText>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <InputText
                    v-model="cotisationAmount"
                    label="Cotisation : CT Ligue"
                  ></InputText>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <InputText
                    v-model="fraisGestion"
                    label="Frais de gestion"
                  ></InputText>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <InputText v-model="licence" label="Licences"></InputText>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <InputText
                    v-model="licenceDecouverte"
                    label="Licences découverte"
                  ></InputText>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-content-around align-items-center"
                >
                  <span class="mb-0"
                    >Montant total affecté : {{ globalAmount }} €</span
                  >
                  <v-btn type="submit" class="btn btn-primary mt-5"
                    >Enregistrer</v-btn
                  >
                </v-col>
              </v-row>
            </ValidateForm>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="isAdmin">
        <v-row class="m-0">
          <v-col class="d-flex" cols="12">
            <h3 class="title-sous-section">Détails du virement</h3>
            <v-divider class="ml-5"></v-divider>
          </v-col>
          <v-col cols="12">
            <ValidateForm @validated="saveVirement" :showFieldObl="false">
              <v-row>
                <v-col sm="12" md="6" lg="6">
                  <DatePicker
                    v-model="changedDateVIR"
                    rules="min:3"
                    label="Date de réception du virement"
                    required
                  ></DatePicker>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <InputText
                    v-model="globalAmountVIR"
                    label="Montant global du virement"
                    required
                  ></InputText>
                </v-col>
                <v-col cols="12">
                  <h3 class="title-sous-section">Nature de l'opération</h3>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <InputText
                    v-model="affiliationAmountVIR"
                    label="Affiliation"
                  ></InputText>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <InputText
                    v-model="cotisationAmountVIR"
                    label="Cotisation : CT Ligue"
                  ></InputText>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <InputText
                    v-model="fraisGestionVIR"
                    label="Frais de gestion"
                  ></InputText>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <InputText v-model="licenceVIR" label="Licences"></InputText>
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <InputText
                    v-model="licenceDecouverteVIR"
                    label="Licences découverte"
                  ></InputText>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-content-around align-items-center"
                >
                  <span class="mb-0"
                    >Montant total affecté : {{ globalAmountVIR }} €</span
                  >
                  <v-btn type="submit" class="btn btn-primary mt-5"
                    >Enregistrer</v-btn
                  >
                </v-col>
              </v-row>
            </ValidateForm>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <PrintDepense ref="depenses" :print="print" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SubBar from "@/components/Common/Common__SubBar.vue";
import ValidateForm from "@/components/utils/form.vue";
import InputText from "@/components/utils/input.vue";
import PrintDepense from "@/components/Structure/Finance/PrintDepense.vue";

import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";

export default {
  components: {
    SubBar,
    ValidateForm,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),
    InputText,
    PrintDepense,
  },
  data: () => ({
    months: [
      { id: 1, mois: "Janvier" },
      { id: 2, mois: "Février" },
      { id: 3, mois: "Mars" },
      { id: 4, mois: "Avril" },
      { id: 5, mois: "Mai" },
      { id: 6, mois: "Juin" },
      { id: 7, mois: "Juillet" },
      { id: 8, mois: "Août" },
      { id: 9, mois: "Spetembre" },
      { id: 10, mois: "Octobre" },
      { id: 11, mois: "Novembre" },
      { id: 12, mois: "Décembre" },
    ],
    years: [],
    month: "",
    year: "",
    changedDate: null,
    globalAmount: null,
    bank: "",
    bankCheck: "",
    affiliationAmount: null,
    cotisationAmount: null,
    fraisGestion: null,
    licence: null,
    licenceDecouverte: null,
    changedDateVIR: null,
    globalAmountVIR: null,
    affiliationAmountVIR: null,
    cotisationAmountVIR: null,
    fraisGestionVIR: null,
    licenceVIR: null,
    licenceDecouverteVIR: null,
    print: {
      period: "",
      licence: [],
      cotisation: [],
      formation: [],
      boutique: [],
      administratif: [],
      licenceAmount: {
        debit: 0,
        credit: 0,
      },
      cotisationAmout: {
        debit: 0,
        credit: 0,
      },
      formationAmout: {
        debit: 0,
        credit: 0,
      },
      boutiqueAmount: {
        debit: 0,
        credit: 0,
      },
      administratifAmount: {
        debit: 0,
        credit: 0,
      },
    },
    route_name: "",
  }),

  async created() {
    const url = this.$route.path.split("/");
    const route = url[1];
    if (route == "gestion-des-finances")
      this.route_name = "gestion_des_finances__dashboard";
    else if (route == "gestion-comptabilite")
      this.route_name = "gestion_comptabilite__dashboard";
    console.log("this.route_name", this.route_name);
    await this.setData();
  },

  computed: {
    ...mapGetters("saisons", ["currentSaison"]),
    ...mapGetters("structure", ["currentStructureId", "currentStructure"]),
    ...mapGetters("user", ["currentUser"]),
    ...mapGetters("user", ["can", "getRole"]),

    subBarTitle() {
      return "Récapitulatif mensuel";
    },

    isAdmin() {
      let role = this.getRole.SlugRole;
      if (role === "super_administrateur") return true;
      return false;
    },
  },

  methods: {
    ...mapActions("utilisateurs", ["createFluxFinancierDetail"]),
    ...mapActions("basics", ["getCurrentAffiliationTarif"]),
    ...mapActions("finances", ["queryFluxFinanciers"]),
    ...mapActions("saisons", ["getSaisons"]),

    async setData() {
      const allSeason = await this.getSaisons();
      this.years = allSeason.filter((season) => season.id >= 2022);
      console.log("years !!!!!!!!!!", this.years);
    },

    async viewSpend() {
      var firstDayOfMonth = new Date(this.month + "/01/" + this.year);
      var lastDayOfMonth = new Date(this.year, this.month, 0);

      const whereClause = {
        Z_DateCreation: {
          _gte: this.$moment(firstDayOfMonth).toISOString(),
          _lte: this.$moment(lastDayOfMonth).toISOString(),
        },
        STR_Structure: {
          id: { _eq: this.currentStructureId },
        },
      };

      const response = await this.queryFluxFinanciers({
        whereClause: whereClause,
      });

      const fluxfiancier = this.sortFluxFinancierDomaine(response);

      let concernedPeriod =
        this.months.find((x) => x.id === this.month).mois + " " + this.year;
      this.print.period = concernedPeriod;

      this.sortByDomain(fluxfiancier);
      this.sumAmount();
      if (fluxfiancier.length != 0) this.printDepense(fluxfiancier);
      else error_notification("Aucune données à télécharger.");
    },

    async saveChequePaiement() {
      const cheque = true;
      const globalAmount = this.globalAmount ? parseInt(this.globalAmount) : 0;
      const affiliationAmount = this.affiliationAmount
        ? parseInt(this.affiliationAmount)
        : 0;
      const cotisationAmount = this.cotisationAmount
        ? parseInt(this.cotisationAmount)
        : 0;
      const fraisGestion = this.fraisGestion ? parseInt(this.fraisGestion) : 0;
      const licence = this.licence ? parseInt(this.licence) : 0;
      const licenceDecouverte = this.licenceDecouverte
        ? parseInt(this.licenceDecouverte)
        : 0;

      try {
        this.createFluxFinancier(
          globalAmount,
          affiliationAmount,
          cotisationAmount,
          fraisGestion,
          licence,
          licenceDecouverte,
          cheque
        );
        success_notification("Paiement par chèque enregistré");
      } catch (e) {
        error_notification("Oups, une erreur est survenue...");
      }
    },

    saveVirement() {
      const cheque = false;
      const globalAmount = this.globalAmountVIR
        ? parseInt(this.globalAmountVIR)
        : 0;
      const affiliationAmount = this.affiliationAmountVIR
        ? parseInt(this.affiliationAmountVIR)
        : 0;
      const cotisationAmount = this.cotisationAmountVIR
        ? parseInt(this.cotisationAmountVIR)
        : 0;
      const fraisGestion = this.fraisGestionVIR
        ? parseInt(this.fraisGestionVIR)
        : 0;
      const licence = this.licenceVIR ? parseInt(this.licenceVIR) : 0;
      const licenceDecouverte = this.licenceDecouverteVIR
        ? parseInt(this.licenceDecouverteVIR)
        : 0;

      try {
        this.createFluxFinancier(
          globalAmount,
          affiliationAmount,
          cotisationAmount,
          fraisGestion,
          licence,
          licenceDecouverte,
          cheque
        );
        success_notification("Virement enregistré");
      } catch (e) {
        error_notification("Oups, une erreur est survenue...");
      }
    },

    async createFluxFinancier(
      totalAmount,
      affiliation,
      cotisation,
      frais,
      licence,
      licenceDecouverte,
      cheque
    ) {
      let fluxFinancierDetail = [];
      if (affiliation !== 0) {
        const tarif = await this.getCurrentAffiliationTarif({
          ID_Saison: this.currentSaison.id,
          NomTarif: "affiliation_EA_MA",
        });
        const detail = {
          ID_Saison: this.currentSaison.id,
          EST_Debit: false,
          NomFluxFinancierDetail: `Affiliation structure ${this.currentStructure.STR_Structure.NomStructure}`,
          ID_Utilisateur: this.currentUser.id,
          MontantHT: affiliation,
          MontantTTC: affiliation,
          MontantTVA: 0,
          ID_Tarif: tarif[0].id,
          FIN_CodeAnalytique: 0,
          FIN_CodeCompta: 0,
        };
        fluxFinancierDetail.push(detail);
      }
      if (cotisation !== 0) {
        const tarif = await this.getCurrentAffiliationTarif({
          ID_Saison: this.currentSaison.id,
          NomTarif: "affiliation_club",
        });
        const detail = {
          ID_Saison: this.currentSaison.id,
          EST_Debit: false,
          NomFluxFinancierDetail: `Cotisation CT/Ligue ${this.currentStructure.STR_Structure.NomStructure}`,
          ID_Utilisateur: this.currentUser.id,
          MontantHT: cotisation,
          MontantTTC: cotisation,
          MontantTVA: 0,
          ID_Tarif: tarif[0].id,
          FIN_CodeAnalytique: 0,
          FIN_CodeCompta: 0,
        };
        fluxFinancierDetail.push(detail);
      }
      if (frais !== 0) {
        const tarif = await this.getCurrentAffiliationTarif({
          ID_Saison: this.currentSaison.id,
          NomTarif: "affiliation_club",
        });
        const detail = {
          ID_Saison: this.currentSaison.id,
          EST_Debit: false,
          NomFluxFinancierDetail: `Frais de gestion ${this.currentStructure.STR_Structure.NomStructure}`,
          ID_Utilisateur: this.currentUser.id,
          MontantHT: frais,
          MontantTTC: frais,
          MontantTVA: 0,
          ID_Tarif: tarif[0].id,
          FIN_CodeAnalytique: 0,
          FIN_CodeCompta: 0,
        };
        fluxFinancierDetail.push(detail);
      }
      if (licence !== 0) {
        const tarif = await this.getCurrentAffiliationTarif({
          ID_Saison: this.currentSaison.id,
          NomTarif: "Cotisation licence",
        });
        const detail = {
          ID_Saison: this.currentSaison.id,
          EST_Debit: false,
          NomFluxFinancierDetail: `Licences ${this.currentStructure.STR_Structure.NomStructure}`,
          ID_Utilisateur: this.currentUser.id,
          MontantHT: licence,
          MontantTTC: licence,
          MontantTVA: 0,
          ID_Tarif: tarif[0].id,
          FIN_CodeAnalytique: 0,
          FIN_CodeCompta: 0,
        };
        fluxFinancierDetail.push(detail);
      }
      if (licenceDecouverte !== 0) {
        const tarif = await this.getCurrentAffiliationTarif({
          ID_Saison: this.currentSaison.id,
          NomTarif: "Licence découverte",
        });
        const detail = {
          ID_Saison: this.currentSaison.id,
          EST_Debit: false,
          NomFluxFinancierDetail: `Licences découverte ${this.currentStructure.STR_Structure.NomStructure}`,
          ID_Utilisateur: this.currentUser.id,
          MontantHT: licenceDecouverte,
          MontantTTC: licenceDecouverte,
          MontantTVA: 0,
          ID_Tarif: tarif[0].id,
          FIN_CodeAnalytique: 0,
          FIN_CodeCompta: 0,
        };
        fluxFinancierDetail.push(detail);
      }

      let nameflux = "";
      if (cheque)
        nameflux = `Facturation par chèque ${this.currentStructure.STR_Structure.NomStructure}`;
      else
        nameflux = `Facturation par virement ${this.currentStructure.STR_Structure.NomStructure}`;

      const fluxStructure = {
        ID_FluxFinancierDebit: 0,
        ID_Saison: this.currentSaison.id,
        ID_Structure: this.currentStructureId,
        ID_Traitement: 1,
        ID_Utilisateur: this.currentUser.id,
        MontantHT: totalAmount,
        MontantTTC: totalAmount,
        MontantTVA: 0,
        EST_Debit: false,
        NomFluxFinancier: nameflux,
        EST_Reglement: false,
        ID_CategoryFlux: 3,
        MKP_FluxFinancierDetail: {
          data: fluxFinancierDetail,
          on_conflict: {
            constraint: "MKP_FluxFinancierDetail_pkey",
            update_columns: ["ID_Tarif", "MontantHT", "MontantTTC"],
          },
        },
      };

      const insertedFlux = await this.createFluxFinancierDetail({
        flux: fluxStructure,
      });
    },

    sortFluxFinancierDomaine(items) {
      var fluxstructure = this.groupBy(items, function(item) {
        if (item.LIST_MouvementDomaine) return [item.LIST_MouvementDomaine.id];
      });

      return fluxstructure;
    },

    groupBy(array, fonction) {
      var groups = {};
      array.forEach(function(o) {
        var group = JSON.stringify(fonction(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map(function(group) {
        return groups[group];
      });
    },

    printDepense(item) {
      this.$refs["depenses"].printDepense(item);
    },

    sortByDomain(items) {
      this.print.licence = items.filter(
        (item) => item[0].LIST_MouvementDomaine.id === 1
      );
      this.print.cotisation = items.filter(
        (item) => item[0].LIST_MouvementDomaine.id === 2
      );
      this.print.formation = items.filter(
        (item) => item[0].LIST_MouvementDomaine.id === 3
      );
      this.print.boutique = items.filter(
        (item) => item[0].LIST_MouvementDomaine.id === 4
      );
      this.print.administratif = items.filter(
        (item) => item[0].LIST_MouvementDomaine.id === 5
      );
    },

    sumAmount() {
      this.print.licence[0] &&
        this.print.licence[0].map((item) => {
          if (!item.EST_Debit) this.print.licenceAmount.debit += item.MontantHT;
          else this.print.licenceAmount.credit += item.MontantHT;
        });
      this.print.cotisation[0] &&
        this.print.cotisation[0].map((item) => {
          if (!item.EST_Debit)
            this.print.cotisationAmout.debit += item.MontantHT;
          else this.print.cotisationAmout.credit += item.MontantHT;
        });
      this.print.formation[0] &&
        this.print.formation[0].map((item) => {
          if (!item.EST_Debit)
            this.print.formationAmout.debit += item.MontantHT;
          else this.print.formationAmout.credit += item.MontantHT;
        });
      this.print.boutique[0] &&
        this.print.boutique[0].map((item) => {
          if (!item.EST_Debit)
            this.print.boutiqueAmount.debit += item.MontantHT;
          else this.print.boutiqueAmount.credit += item.MontantHT;
        });
      this.print.administratif[0] &&
        this.print.administratif[0].map((item) => {
          if (!item.EST_Debit)
            this.print.administratifAmount.debit += item.MontantHT;
          else this.print.administratifAmount.credit += item.MontantHT;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  background: red;
}
.title-section {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  display: flex;
  align-items: center;
  letter-spacing: -1px;

  color: #020918;
}
.container-alert {
  border-left: 2px solid #082b6e;
  background: rgba(8, 43, 110, 0.1);
  span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    display: flex;
    align-items: center;

    /* Primary/Royal */

    color: #082b6e;
  }
}
.container-print {
  background: #ffffff;
  box-shadow: 2px 4px 8px -2px rgba(8, 43, 110, 0.25);
}
.title-sous-section {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  /* Royal/100 */

  color: #020918;
}
</style>
