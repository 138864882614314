<template>
  <div v-if="loader" class="ma_licence__loading-container">
    <Loading :loading="true" />
  </div>
  <v-row class="h-100 m-0 position-relative" v-else>
    <v-col sm="12" xs="12" md="3" lg="4" class="left-bar h-100">
      <v-row class="px-3">
        <v-col class="mt-5" cols="12">
          <h1 class="title-place-marche">
            <v-icon class="icon-color first-blue mr-3">mdi-account-box</v-icon>
            INFORMATION DU COMPTE
          </h1>
        </v-col>
        <v-col cols="12">
<!--          <p class="section-place-marche mb-0">-->
<!--            Date de création : <span> {{ accountDateCreation }} </span>-->
<!--          </p>-->
          <p class="section-place-marche mb-0">
            Catégorie : <span>Personne morale</span>
          </p>
        </v-col>
        <v-col>
          <p class="section-place-marche">
            <v-icon class="icon-color first-blue mr-3"
              >mdi-scale-balance</v-icon
            >
            SOLDE
          </p>
          <span class="section-place-marche-prix">
            {{ accountBalance ? accountBalance : 'En cours...' }}</span
          >
        </v-col>
        <v-col
          class="ml-3 mt-3 d-flex rib"
          cols="11"
          v-if="!hasMultipleLemonwayRib && haveIbanKyc && haveIbanKyc.status === 5"
        >
          <div class="avatar-bank mr-3">
            <v-icon class="icon-color first-blue">mdi-bank</v-icon>
          </div>
          <div class="rib-bank">
            <p class="mb-0">{{ currentRib.bankName }}</p>
            <!-- <span class="mb-3">Esplanade Hôtel de Ville, 74000 Annecy</span> -->
            <p class="coordonne mb-0">
              Iban &nbsp;<span class="coordonne">
                {{ currentRib.iban }}
              </span>
            </p>
            <p class="coordonne">
              BIC &nbsp;<span class="coordonne">
                {{ currentRib.bic }}
              </span>
            </p>
          </div>
        </v-col>
        <v-col class="ml-3 mt-3 d-flex rib" cols="11" v-else>
          <div class="rib-bank d-block iban-no">
            <span class="d-block mb-0 pl-3 pt-3 pb-1 section-place-marche-prix" style="font-weight: 500;">
              Information : Actuellement votre structure est dans l'incapacité
              de procéder à un transfert d'argent.
              <span v-if="!haveIbanKyc || hasMultipleLemonwayRib" class="section-place-marche-prix" style="font-weight: 500;">Merci de contacter le support FFME à l’adresse <a href="mailto:myffme-structure@ffme.fr" class="ml-1">myffme-structure@ffme.fr</a></span>
            </span>

            <span
              class="mb-0 pl-3 pb-3 section-place-marche-prix"
              v-if="haveIbanKyc && haveIbanKyc.status === 4"
              style="font-weight: 700;"
              >Statut IBAN: En cours de vérification
              <span
                :class="
                  haveIbanKyc.status === 4
                    ? 'status waiting ml-1'
                    : 'status declined ml-1'
                "
              ></span>
            </span>
          </div>
        </v-col>
        <v-col cols="12" class="d-flex justify-content-center">
          <v-btn
            type="submit"
            @click="transfertArgent()"
            class="btn btn-primary mt-5"
            :disabled="isDisabled"
            >Transférer de l’argent</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
    <v-col sm="12" xs="12" md="9" lg="8" class="h-100 overflow-auto p-0">
      <v-tabs v-model="tab" @change="getTab">
        <v-tab
          v-for="item in items"
          :key="item"
          class="main-place-marche-tab"
          >{{ item }}</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card class="p-0" flat>
            <v-card-text class="p-0"> </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card class="p-0" flat>
            <v-card-text class="p-0"> </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card class="p-0" flat>
            <v-card-text class="p-0"> </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card class="p-0" flat>
            <v-card-text class="p-0"> </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <PmTransaction class="p-0 m-0" :loading="loading" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loading from '@/components/LoadingIndicator.vue';
import {
  error_notification,
  success_notification,
} from '@/plugins/notifications';

import PmTransaction from '@/components/User/Kyc/PmTransaction.vue';
import { bus } from '@/main';
import fetch from "node-fetch";
import { getStructureRib } from '../../../plugins/structureService';

export default {
  data() {
    return {
      loading: false,
      tab: 0,
      items: ['Toutes les transactions', 'P2P', 'Money-In', 'Money-Out'],
      allItems: [],
      haveAccount: null,
      accountDateCreation: null,
      accountBalance: '',
      account: null,
      currentTab: null,
      currentRib: null,
      allIbansKyc: [],
      haveIbanKyc: null,
      loader: false,
      wrongFormatIban: false,
      hasMultipleLemonwayRib: false,
    };
  },
  components: {
    PmTransaction,
    Loading,
  },

  async created() {
    console.log('created tabssssss', this.tab);
    this.currentTab = this.tab;

    bus.$on('search-filter-date', (startDate, endDate) => {
      console.log('startDate', startDate);
      console.log('endDate', endDate);

      this.getAllPaymentTransaction(startDate, endDate);
    });

    bus.$on('reload-money-outs', (startDate, endDate) => {
      console.log('startDate', startDate);
      console.log('endDate', endDate);

      this.getAllPaymentTransaction(startDate, endDate);
    });

    await this.setData();
  },

  computed: {
    ...mapGetters('structure', ['currentStructureId', 'currentStructure']),

    isDisabled() {
      if (!this.hasMultipleLemonwayRib && this.haveIbanKyc?.status === 5) return false;
      return true;
    },
  },

  methods: {
    ...mapActions('utilisateurs', ['getUserPaymentAccount']),

    async setData() {
      this.loader = true;
      await this.getAllPaymentTransaction();
      await this.getRetrieveAccount();
      await this.getMoneysOutsRetrieveIban();
      // const getAllPaymentAccount = await this.getUserPaymentAccount();

      // if (getAllPaymentAccount) {
      //   this.haveAccount = getAllPaymentAccount.find(
      //     (account) => account.ID_Structure == this.currentStructureId
      //   );
      //   this.accountDateCreation = this.$moment(
      //     this.haveAccount.Z_DateCreation
      //   ).format('DD/MM/YYYY');
      //   console.log('this.haveAccount', this.haveAccount);
      // }

      const structureRib = await getStructureRib(this.currentStructureId);
      console.log('structureRib ---', structureRib);

      if (structureRib) {
        this.currentRib = structureRib;
        console.log('this.currentRib', this.currentRib);
        this.haveIbanKyc = this.allIbansKyc?.filter(
          (data) => data.iban === this.currentRib?.iban && (data.status === 4 || data.status === 5)
        );
        this.hasMultipleLemonwayRib = this.haveIbanKyc.length > 1;
        this.haveIbanKyc = this.haveIbanKyc[0];
        if (this.haveIbanKyc) {
          this.$store.commit('structure/SET_MONEY_OUTS_IBAN', this.haveIbanKyc);
          console.log('this.haveIbanKyc', this.haveIbanKyc);
        }
      } else {
        error_notification('Informations RIB incomplète');
      }

      if (this.currentRib && !this.haveIbanKyc) {
        let payload = {
          accountId: this.currentStructureId, //mila ovaina
          holder: this.currentRib?.holderName,
          bic: this.currentRib?.bic,
          iban: this.currentRib?.iban,
          domiciliation: this.currentRib?.bankName,
        };
        console.log('!haveIbanKyc && currentRib');

        await this.createIbanKyc(payload);
      }
    },

    getTab() {
      console.log('tabssssss', this.tab);
      this.$store.commit('structure/SET_MONEY_OUTS_TAB', this.tab);
    },

    transfertArgent() {
      this.$store.commit('structure/SET_OPEN_MONEY_OUTS', true);
    },

    async getAllPaymentTransaction(startDate, endDate) {
      try {

        const fetch = require('node-fetch');
        let url =  process.env.VUE_APP_FFME_BACK_URL + '/api/kyclemonway/'+ this.currentStructureId +'/transactions';
        if (startDate) {
          url += '?startDate=' + startDate / 1000;
          if (endDate) {
            url += '&endDate=' + endDate / 1000;
          }
        } else if (endDate) {
          url += '?endDate=' + endDate / 1000;
        }
        const response = await fetch(
            url,
            {
              method: 'GET',
              headers: {
                'Authorization': "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
                'Content-Type': 'application/json',
              },
            }
        );
        const res = await response.json();
        if (response.ok) {
          this.$store.commit(
            'structure/SET_MONEY_OUTS', res?.transactions?.value
          );

          this.loading = false;
          this.loader = false;
          return { ok: true };
        } else {
          return { ok: false };
        }
        // error_notification(`Veuillez réessayer plus tard`);
      } catch (error) {
        console.log('erro r:', error);
      }
    },

    async getRetrieveAccount() {
      try {
        const fetch = require('node-fetch');
        const response = await fetch(
            `${process.env.VUE_APP_FFME_BACK_URL}/api/kyclemonway/${this.currentStructureId}`,
            {
              method: 'GET',
              headers: {
                'Authorization': "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
                'Content-Type': 'application/json',
              },
            }
        );

        const json = await response.json();
        console.log('response getretrieveAccount====>', json);

        if (json.account) {
          console.log('mandalooo json getretrieveAccount ====>');
          this.account = json?.account;
          this.accountBalance = `${json.account.balance / 100} €`;
          return { ok: true };
        } else {
          return { ok: false };
        }
      } catch (error) {
        console.log('erro r:', error);
      }
    },

    async getMoneysOutsRetrieveIban() {
      try {
        const fetch = require('node-fetch');

        const response = await fetch(
            `${process.env.VUE_APP_FFME_BACK_URL}/api/kyclemonway/${this.currentStructureId}/iban`,
            {
              method: 'GET',
              headers: {
                'Authorization': "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
                'Content-Type': 'application/json',
              },
            }
        );
        const json = await response.json();
        console.log('response MoneysOutsRetrieveIban====>', json);

        if (json?.ibans) {
          this.allIbansKyc = json?.ibans;

          this.loading = false;
          this.loader = false;
          return { ok: true };
        } else {
          this.loading = false;
          this.loader = false;
          return { ok: false };
        }
      } catch (error) {
        console.log('erro r:', error);
      }
    },

    // --------- create IBAN IF USER HAVE NOT IN LEMONWAY
    async createIbanKyc(payload) {
      try {
        const fetch = require('node-fetch');

        const response = await fetch(
            `${process.env.VUE_APP_FFME_BACK_URL}/api/kyclemonway/addIban`,
            {
              method: 'POST',
              headers: {
                'Authorization': "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
            }
        );

        const json = await response.json();
        console.log('response IBAN create', json);

        if (json?.ibanId) {
          this.loading = false;
          this.loader = false;
          await this.setData();
          return { ok: true };
        } else if (json?.error.code === 221) {
          this.wrongFormatIban = true;
          error_notification(`Mauvais format pour les détails IBAN`);
          this.loading = false;
          this.loader = false;
          return { ok: false };
        } else {
          this.loading = false;
          this.loader = false;
          return { ok: false };
        }
        // error_notification(`Veuillez réessayer plus tard`);
      } catch (error) {
        console.log('erro r:', error);
      }
    },
    // ---------  FIN create IBAN IF USER HAVE NOT IN LEMONWAY
  },
};
</script>

<style lang="scss" scoped>
.iban-no {
  border-left: 2px solid #082b6e;
}
.status {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  &.accepted {
    background: #00be76;
  }
  &.declined {
    background: red;
  }
  &.waiting {
    background: orange;
  }
}
.left-bar {
  background: #e7effd;
}
.icon-color {
  &.first-blue {
    color: #1c3d7a !important;
  }
}
.title-place-marche {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: flex-end;

  /* Grey/100 */

  color: #1c3d7a;
}
.avatar-bank {
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 2px 4px 8px -2px rgba(8, 43, 110, 0.25);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rib-bank {
  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;

    /* Grey/70 */

    color: #90a1be;
    &.coordonne {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 10px !important;
      line-height: 16px;
      /* identical to box height, or 160% */

      display: flex;
      align-items: center;
      letter-spacing: 1px;
      text-transform: uppercase;

      /* Primary/Dark */

      color: #000000 !important;
    }
  }
  span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    /* Grey/50 */

    // color: #bac4d7;
    &.coordonne {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 10px !important;
      line-height: 16px;
      /* identical to box height, or 160% */

      display: flex;
      align-items: center;
      letter-spacing: 1px;
      text-transform: uppercase;

      /* Primary/Dark */

      color: #000000 !important;
    }
  }
}
.section-place-marche {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.section-place-marche-prix {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  letter-spacing: -0.5px;

  /* Primary/Royal */

  color: #082b6e;
}
.rib {
  /* Primary/White */
  background: #ffffff;
  /* Shadow/Royal */
  box-shadow: 2px 4px 8px -2px rgba(8, 43, 110, 0.25);
  border-radius: 4px;
}
.main-place-marche-tab {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* Primary/Royal */
  text-transform: none !important;
  color: #082b6e;
}
</style>
