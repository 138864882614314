<template>
  <div>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle"
        :external="externalLink"
      />
    </v-row>
    <v-row class="row">
      <v-col class="w-100 m-4">
        <VerificationKyc />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SubBar from '@/components/Common/Common__SubBar.vue';
import VerificationKyc from '@/components/User/Kyc/VerificationKyc.vue';

export default {
  data: () => ({
    subBarTitle: 'Gestion de compte lemonway KYC',
    externalLink: false,
  }),

  async created() {
    this.externalLink = `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-comptabilite?structureId=${this.currentStructureId}&appMode=${localStorage.getItem('ffme_user_mode')}`;
  },

  components: { SubBar, VerificationKyc },

  computed: {
    ...mapGetters('structure', ['currentStructureId']),
  },
};
</script>

<style lang="scss">
.test__dematerialise {
  padding: 0px !important;
}
</style>
