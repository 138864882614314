<template>
  <div class="position-relative h-100">
    <div v-if="openMoneyOuts === true" class="h-100 m-0 w-100 send-money">
      <v-row class="h-100 m-0 position-relative overflow-auto">
        <v-col class="p-0" sm="12" xs="12" md="3" lg="4"></v-col>
        <v-col class="p-0" sm="12" xs="12" md="9" lg="8">
          <v-row no-gutters>
            <SubBar
              :title="subBarTitle2"
              :openMoneyOutsWindow="openMoneyOuts"
            />
          </v-row>
          <v-row class="row m-0 bg-light position-relative h-100">
            <v-col class="w-100 p-0 position-relative">
              <pmTransfertArgent />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="position-relative h-100">
      <v-row no-gutters>
        <SubBar
          :title="subBarTitle"
          :external="externalLink"
        />
      </v-row>
      <v-row class="row m-0 position-relative adjusted-height">
        <v-col class="w-100 p-0 position-relative">
          <placeMarcheKyc />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import SubBar from '@/components/Common/Common__SubBar.vue';
import placeMarcheKyc from '@/components/User/Kyc/PlaceMarcheKyc.vue';
import pmTransfertArgent from '@/components/User/Kyc/PmTransfertArgent.vue';

export default {
  data() {
    return {
      subBarTitle: 'PLACE DE MARCHÉ',
      subBarTitle2: 'Transfert d’argent',
      openMoneyOutsWindow: false,
      externalLink: false,
    };
  },
  async created() {
    this.externalLink = `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-comptabilite?structureId=${this.currentStructureId}&appMode=${localStorage.getItem('ffme_user_mode')}`;
  },

  components: { SubBar, placeMarcheKyc, pmTransfertArgent },

  computed: {
    ...mapGetters('structure', ['currentStructureId']),
    ...mapState({
      openMoneyOuts: (state) => state.structure.openMoneyOuts,
    }),
  },
};
</script>

<style lang="scss">
.test__dematerialise {
  padding: 0px !important;
}

.adjusted-height{
  height: calc(100% - 65px);
}
.send-money {
  z-index: 10;
  position: absolute;
  background: rgba($color: #000000, $alpha: 0.4);
}
</style>
