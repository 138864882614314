<template>
  <v-card
    height="100%"
    width="100%"
    style="max-height: 450px;"
    class="p-3 d-flex flex-column justify-center"
  >
    <v-card-text class="text-center pb-0">
      <p class="subtitle-1 font-weight-bold text-uppercase text--primary">
        {{ title_card }}
      </p>

      <p v-if="subtitle_card.length > 0" class="text--primary">
        {{ subtitle_card }}
      </p>
    </v-card-text>

    <v-card-actions class="justify-center" v-if="isActionAvalaible">
      <v-btn
        v-if="
          ((isDisabled || isComingSoon || !clubIsAffilier) && auth) || blockedAccount
        "
        icon
        exact
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="gray" size="28">mdi-arrow-right</v-icon>
          </template>
          <span v-if="isDisabled && auth">Vous n&apos;avez pas accès à ce module.</span>

          <span v-else-if="isComingSoon && auth"
            >Module prochainement disponible.</span
          >

          <span v-else-if="!clubIsAffilierCurrentSeason"
            >Cette structure n'est pas affiliée sur la saison en cours.</span
          >

          <span v-else-if="!clubIsAffilier"
            >Cette structure n'est pas affiliée.</span
          >

          <span v-else-if="blockedAccount">Cette structure est bloquée.</span>

          <span v-else
            >Vous n'avez pas les droits nécessaire pour accéder à ce module.
            Merci de contacter un administrateur.</span
          >
        </v-tooltip>
      </v-btn>

      <a
        v-else-if="custom_link"
        :href="custom_link"
        :target="target"
        class="subtitle-2 font-weight-light"
      >
        <v-btn icon exact>
          <v-icon color="#00abe7" size="28">mdi-arrow-right</v-icon>
        </v-btn>
      </a>

      <router-link
        v-else-if="auth && canAccess"
        :to="{ name: module_name, params: urlParams }"
        class="subtitle-2 font-weight-light"
      >
        <v-btn icon exact>
          <v-icon color="#00abe7" size="28">mdi-arrow-right</v-icon>
        </v-btn>
      </router-link>

      <router-link
        v-else-if="!auth"
        :to="{ name: module_name, params: urlParams }"
        class="subtitle-2 font-weight-light"
      >
        <v-btn icon exact>
          <v-icon color="#00abe7" size="28">mdi-arrow-right</v-icon>
        </v-btn>
      </router-link>

      <v-btn v-else icon exact>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="gray" size="28">mdi-arrow-right</v-icon>
          </template>
          <span
            >Vous n'avez pas les droits nécessaire pour accéder à ce module.
            Merci de contacter un administrateur.</span
          >
        </v-tooltip>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("user", ["can"]),

    canAccess() {
      return this.can(this.module_name);
    },
  },
  props: {
    title_card: {
      type: String,
      default: "Module name",
    },
    subtitle_card: {
      type: String,
      default: "",
    },

    module_name: {
      type: String,
    },

    auth: {
      type: Boolean,
      default: false,
    },

    custom_link: {
      type: String,
      default: "",
    },
    target: {
      type: String,
      default: "_blank",
    },
    isActionAvalaible: {
      type: Boolean,
      default: true,
    },
    isComingSoon: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    clubIsAffilierCurrentSeason: {
      type: Boolean,
      default: true,
    },
    clubIsAffilier: {
      type: Boolean,
      default: true,
    },
    blockedAccount: {
      type: Boolean,
      default: false,
    },
    urlParams: {
      type: Object,
      default: undefined,
    },
  },
};
</script>
